import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { router } from './routers/router';
import { StoreProvider } from './hooks/useStore';
import RootStore from './stores';

import './styles/index.scss';
import { LiffProvider } from './components/liff-provider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <LiffProvider>
            <StoreProvider store={new RootStore()}>
                <RouterProvider router={router} />
            </StoreProvider>
        </LiffProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
