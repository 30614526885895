import React from 'react';

const PolicyPage: React.FC = () => {
    return (
        <div className='max-w-4xl mx-auto p-6 bg-white shadow-md'>
            {/* Policy Section */}
            <h1 className='text-3xl font-bold mb-4'>利用規約</h1>

            <section className='mb-6'>
                <h2 className='text-xl font-semibold mb-2'>1. はじめに</h2>
                <p className='text-gray-700'>
                    本アプリケーション（以下「本アプリ」）は、LINEミニアプリを通じてユーザーに対して請求書情報を提供することを目的としています。ご利用に際しては、以下の利用規約をよくお読みいただき、同意された場合のみご利用ください。
                </p>
            </section>

            <section className='mb-6'>
                <h2 className='text-xl font-semibold mb-2'>2. データの取り扱い</h2>
                <p className='text-gray-700'>
                    本アプリは、請求書情報の取得や提供を行うために、必要最低限の情報を収集・利用します。LINEアカウントを使用してログインすることで、ユーザーのLINEプロフィール情報を使用することがありますが、これらのデータは他の目的で使用されることはありません。
                </p>
            </section>

            <section className='mb-6'>
                <h2 className='text-xl font-semibold mb-2'>3. 利用目的</h2>
                <p className='text-gray-700'>
                    本アプリは、ユーザーに対して迅速かつ正確な請求書情報を提供するために使用されます。取得した情報は、請求書管理および関連するサービスを提供するためにのみ使用され、第三者に開示されることはありません。
                </p>
            </section>

            <section className='mb-6'>
                <h2 className='text-xl font-semibold mb-2'>4. ユーザーの責任</h2>
                <p className='text-gray-700'>
                    ユーザーは、本アプリを通じて取得した情報を正確かつ適切に管理する責任を負います。個人情報や請求書情報の取り扱いには十分にご注意ください。
                </p>
            </section>

            <section className='mb-6'>
                <h2 className='text-xl font-semibold mb-2'>5. 免責事項</h2>
                <p className='text-gray-700'>
                    本アプリの利用により生じた損害について、運営者は一切の責任を負いかねます。また、予告なく本アプリの提供を中断または終了する場合があります。
                </p>
            </section>

            <section className='mb-6'>
                <h2 className='text-xl font-semibold mb-2'>6. 改訂</h2>
                <p className='text-gray-700'>
                    本利用規約は、必要に応じて改訂されることがあります。変更があった場合は、アプリ内で通知いたします。
                </p>
            </section>

            <footer className='mt-10'>
                <p className='text-gray-600 text-sm'>最終更新日: 2024年10月7日</p>
            </footer>

            {/* Term of Service Section */}
            <div className='mt-10'>
                <h1 className='text-3xl font-bold mb-4'>サービス利用規約</h1>

                <section className='mb-6'>
                    <h2 className='text-xl font-semibold mb-2'>1. サービス提供</h2>
                    <p className='text-gray-700'>
                        本アプリは、ユーザーに対して請求書情報の提供を行うサービスです。LINEミニアプリを介して提供されるすべての機能とサービスは、本利用規約に従って提供されます。
                    </p>
                </section>

                <section className='mb-6'>
                    <h2 className='text-xl font-semibold mb-2'>2. サービスの変更</h2>
                    <p className='text-gray-700'>
                        運営者は、ユーザーへの事前通知なしに本アプリの内容および機能を変更、追加または廃止することができます。これに伴う責任は一切負いかねます。
                    </p>
                </section>

                <section className='mb-6'>
                    <h2 className='text-xl font-semibold mb-2'>3. サービスの中断・終了</h2>
                    <p className='text-gray-700'>
                        運営者は、予告なく本アプリの一時的な中断または終了を行う権利を有します。この際、ユーザーが受けるいかなる不利益・損害に対しても責任を負いません。
                    </p>
                </section>

                <section className='mb-6'>
                    <h2 className='text-xl font-semibold mb-2'>4. 禁止事項</h2>
                    <p className='text-gray-700'>
                        ユーザーは、本アプリを不正利用しないことを誓約します。違法行為、システムへの不正アクセス、または他者の権利を侵害する行為は禁止されています。
                    </p>
                </section>

                <section className='mb-6'>
                    <h2 className='text-xl font-semibold mb-2'>5. 免責事項</h2>
                    <p className='text-gray-700'>
                        本アプリの利用により発生したいかなる損害に対しても、運営者は一切の責任を負いません。本アプリは「現状のまま」提供され、エラーや障害が発生する可能性があることをご了承ください。
                    </p>
                </section>

                <section className='mb-6'>
                    <h2 className='text-xl font-semibold mb-2'>6. 準拠法および裁判管轄</h2>
                    <p className='text-gray-700'>
                        本利用規約は、日本法に準拠します。また、本アプリに関連するすべての紛争は、日本国内の管轄裁判所で解決されます。
                    </p>
                </section>

                <footer className='mt-10'>
                    <p className='text-gray-600 text-sm'>最終更新日: 2024年10月7日</p>
                </footer>
            </div>
        </div>
    );
};

export default PolicyPage;
