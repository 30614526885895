import { action, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';
import { handleSaveFile } from '@/utils/file';
import { AxiosErrorApi, AxiosRequestApi, AxiosResponseApi } from '@/types/axios';
import ApiService from '@/apis/ApiService';
import { toastify } from '@/utils/toastify';
import { t } from 'i18next';
import { ResponseData } from '@/types/http';

export interface CallApiOptions {
    handleErrorBySelf?: boolean,
    callbackError?: (response: AxiosResponseApi) => void,
    disableAlertError?: boolean,
    hideLoading?: boolean,
    isLoadingTable?: boolean
}

export default class ApiStore extends BaseStore {
    isLoading: boolean = false;
    isLoadingTable: boolean = false;
    requestCount: number = 0;
    requestCountTable: number = 0;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            isLoading: observable,
            isLoadingTable: observable,
            showLoading: action.bound,
            hideLoading: action.bound,
            setLoading: action.bound,
            handlerGeneralResponseSuccess: action.bound,
            handlerGeneralResponseError: action.bound,
            call: action.bound
        });
    }

    showLoading() {
        this.isLoading = true;
    }

    hideLoading() {
        this.isLoading = false;
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    handlerGeneralResponseSuccess = (response: AxiosResponseApi): ResponseData => {
        if (response && response.status === 200) {
            const type = response.headers['content-type'];
            const isExport = [
                'application/pdf',
                'application/zip',
                'text/csv; charset=utf-8',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ''
            ].includes(type);
            if (isExport) {
                handleSaveFile(response);
            }
            return response.data;
        }

        throw response;
    };

    handlerGeneralResponseError = (
        response?: AxiosResponseApi,
        { handleErrorBySelf, callbackError, disableAlertError }: Partial<CallApiOptions> = {}
    ): ResponseData => {
        if (handleErrorBySelf || !response) {
            throw response;
        }

        try {
            if (response.status === 401 || response.status === 403) {
                this.rootStore.modalStore.showErrorModal({ content: response.data.message || t('messages.account_invalid'), showCloseIcon: false });
                setTimeout(() => {
                    this.rootStore.authStore.clearAuthentication();
                }, 1500);
            } else if (response.status >= 500) {
                this.rootStore.modalStore.showErrorModal({
                    content: response.data.message || t('messages.api_response_no_network_or_server_error')
                });
            } else if (response.status) {
                if (!disableAlertError) {
                    this.rootStore.modalStore.showErrorModal({
                        content: response.data.message || t('messages.api_response_no_message')
                    });
                }
                callbackError && callbackError(response);
            }
        } catch (error) {
            this.rootStore.modalStore.showErrorModal({
                content: t('messages.api_response_no_network_or_server_error')
            });
        } finally {
            throw response;
        }
    };

    call(
        contextRequest: ApiService,
        request: AxiosRequestApi,
        payload?: any,
        { handleErrorBySelf, callbackError, disableAlertError, hideLoading, isLoadingTable }: CallApiOptions = {}
    ): Promise<ResponseData> {
        this.requestCount += 1;
        if (!hideLoading) {
            this.showLoading();
        }
        if (isLoadingTable) {
            this.requestCountTable += 1;
            this.isLoadingTable = true;
        }
        return request.apply(contextRequest, [payload])
            .then(this.handlerGeneralResponseSuccess)
            .catch((response: AxiosErrorApi) => this.handlerGeneralResponseError(response.response, { handleErrorBySelf, callbackError, disableAlertError }))
            .finally(() => {
                this.requestCount -= 1;
                if (this.requestCount === 0) {
                    this.hideLoading();
                }
                if (isLoadingTable) {
                    this.requestCountTable -= 1;
                    if (this.requestCountTable === 0) {
                        this.isLoadingTable = false;
                    }
                }
            });
    }
}
