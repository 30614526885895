export const stringEnum = {
    authorized: 'authorized',
    profileNotFound: 'profile-not-found',
    activated: 'activated',
    storeUser: 'storeUser'
};

export const keyStorage = {
    TOKEN: 'token',
    PROFILE_USER: 'profile_user',
    LINE_PROFILE_LINK: 'line_profile_link',
    UUID: 'qr_uuid',
    LIFF_ID: 'liff_id',
    APP_VERSION: 'app_version'
};

export const CONST_BUILD = {
    VERSION: '1.0.0'
};
