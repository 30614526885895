import { UserApi } from '@/apis';
import RootStore from '.';
import BaseStore from './BaseStore';
import { User } from '@/types/user';
import { flow, makeObservable, observable } from 'mobx';
import { GetUsersRequest, GetUsersResponse } from '@/types/http-payload/user';
import { ResponseData } from '@/types/http';
import { TableState } from '@tanstack/react-table';
import { Customer, Profile } from '@/types/auth';
import FileApi from '@/apis/FileApi';

export default class UserStore extends BaseStore {
    users: User[] = [];
    api: UserApi;
    fileApi: FileApi;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            users: observable,
            getUsers: flow.bound,
            getMyInfo: flow.bound
        });
        this.api = new UserApi();
        this.fileApi = new FileApi();
    }

    *getMyInfo() {
        try {
            const res: ResponseData<Customer> = yield this.rootStore.apiStore.call(this.fileApi, this.fileApi.getMyInfo, null, { hideLoading: true });
            if (res.ok) {
                return res.data;
            }
            return undefined;
        } catch (error) {
            return undefined;
            console.log(error);
        }
    }

    *getUsers(searchParams: GetUsersRequest, paging?: TableState) {
        // try {
        //     const payload = { ...this.convertPagingFromTableToRequest(paging ?? this.paging), ...searchParams };
        //     const res: ResponseData<GetUsersResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getUsers, payload);
        //     if (res.ok) {
        //         this.users = res.data.elements;
        //         this.paging = {
        //             ...this.paging,
        //             sorting: paging?.sorting ?? [],
        //             pagination: this.convertPaginationFromRequestToTable(res.data.pagination)
        //         };
        //         this.totalRecord = res.data.pagination.totalRecord;
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    }

    clean(): void {
        super.clean();
        this.users = [];
    }
}
