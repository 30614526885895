import App from '@/App';
import { ROUTES } from '@/configs/constants';
import withAuthentication from '@/hooks/withAuthentication';
import withNonAuthentication from '@/hooks/withNonAuthentication';
import AdminPanelLayout from '@/layouts/AdminPanelLayout';
import FormPanelLayout from '@/layouts/FormPanelLayout';
import { LoginPage, NotFoundPage, HomePage, PolicyPage, TermOfServicePage, ViewInvoice } from '@/pages';
import { createBrowserRouter, redirect } from 'react-router-dom';

const AdminPanelLayoutWithAuthentication = withAuthentication(AdminPanelLayout);
const FormPanelLayoutWithNoneAuthentication = withNonAuthentication(FormPanelLayout);

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                index: true,
                loader: () => redirect(ROUTES.login.href)
            },
            {
                path: '/',
                element: <FormPanelLayoutWithNoneAuthentication />,
                children: [
                    {
                        path: ROUTES.login.href,
                        element: <LoginPage />
                    },
                    {
                        path: ROUTES.policy.href,
                        element: <PolicyPage />
                    },
                    {
                        path: ROUTES.termOfService.href,
                        element: <TermOfServicePage />
                    }
                ]
            },
            {
                path: '/',
                element: <AdminPanelLayoutWithAuthentication />,
                children: [
                    {
                        path: ROUTES.home.href,
                        element: <HomePage />
                    },
                    {
                        path: ROUTES.viewInvoice.href,
                        element: <ViewInvoice />
                    }
                ]
            },
            {
                path: '*',
                element: <NotFoundPage />
            }
        ]
    }
]);
