import axios from 'axios';
import ApiService from './ApiService';
import { getStorage } from '@/utils/browsers';
import { keyStorage } from '@/constant/string';

export default class AuthApi extends ApiService {
    login(payload) {
        return this.post('login', payload);
    }

    logout() {
        return this.post('logout');
    }

    getProfile() {
        return this.get('profile');
    }

    signinByQrCode(payload) {
        const { line_id, uuid } = payload;
        return this.post(`verify/${uuid}`, line_id && { line_id: line_id });
    }

    async getProfileLine(payload) {
        try {
            const liff_id = getStorage(keyStorage.LIFF_ID);
            const client_id = liff_id.substring(0, liff_id.indexOf('-'));
            const data = new URLSearchParams();
            data.append('id_token', payload);
            data.append('client_id', client_id);
            const res = await axios.post('https://api.line.me/oauth2/v2.1/verify', data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }

            });
            return res.data || undefined;
        } catch (error) {
            return undefined;
        }
    }
}
