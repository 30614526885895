import FileApi from '@/apis/FileApi';
import BaseStore from './BaseStore';
import { action, flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import { BodyGetInvoices, File, GetInvoicesResponse } from '@/types/files';
import { MonthSelect } from '@/types/user';
import { ResponseData } from '@/types/http';
import { LoginPostResponse } from '@/types/http-payload/auth';

export default class FileStore extends BaseStore {
    api: FileApi;
    listInvoices: File[] = [];
    keepSearchParams: BodyGetInvoices = {} as BodyGetInvoices;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            keepSearchParams: observable,
            listInvoices: observable,
            getListInvoices: flow.bound,
            getDetailInvoice: flow.bound
        });
        this.api = new FileApi();
    }

    *getListInvoices(payload) {
        try {
            const res: ResponseData<GetInvoicesResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getInvoices, payload);
            if (res?.ok) {
                this.listInvoices = res.data.elements;
                this.paging = {
                    ...this.paging,
                    sorting: this.paging?.sorting ?? [],
                    pagination: this.convertPaginationFromRequestToTable(res.data.paginate)
                };
                this.totalRecord = res.data.paginate.totalRecord;
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *getDetailInvoice(id) {
        try {
            return yield this.rootStore.apiStore.call(this.api, this.api.getInvoice, id, { hideLoading: true });
        } catch (error) {
            return false;
        }
    }
}
