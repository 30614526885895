import React from 'react';

const PolicyPage: React.FC = () => {
    return (
        <div className='w-full h-screen flex justify-center items-center'>
            <iframe
                src='https://docs.google.com/document/d/1xJWzUq_gg82K13mF7Yr3jmYTb1fPVHmx6PWfUCyGtd4/view?tab=t.0'
                title='Privacy Policy'
                className='w-full h-full border-none'
            />
        </div>

    );
};

export default PolicyPage;
