import { action, flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';
import { getStorage, removeStorage, saveLocalStorage } from '@/utils/browsers';
import { AuthApi } from '@/apis';
import { ROUTES } from '@/configs/constants';
import { toastify } from '@/utils/toastify';
import { t } from 'i18next';
import { Customer, Profile, ResQrLogin } from '@/types/auth';
import { ResponseData } from '@/types/http';
import { LoginPostRequest, LoginPostResponse } from '@/types/http-payload/auth';
import axios from 'axios';
import { keyStorage } from '@/constant/string';
import liff from '@line/liff';

export default class AuthStore extends BaseStore {
    profile: Customer = getStorage(keyStorage.PROFILE_USER);
    token: string = getStorage(keyStorage.TOKEN);
    api: AuthApi;
    customer?: Customer;
    liff_id: string = getStorage(keyStorage.LIFF_ID);

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            profile: observable,
            token: observable,
            login: flow.bound,
            logout: action.bound,
            getProfile: flow.bound,
            clearAuthentication: action.bound,
            customer: observable,
            signinQrCode: flow.bound,
            setAuth: action.bound,
            setProfile: action.bound,
            liff_id: observable,
            setLiffID: action.bound
        });
        this.api = new AuthApi();
    }

    *login(payload: LoginPostRequest) {
        try {
            const res: ResponseData<LoginPostResponse> = yield this.rootStore.apiStore.call(this.api, this.api.login, payload);
            if (res?.ok) {
                this.token = res.data.token;
                saveLocalStorage(keyStorage.TOKEN, res.data.token);
                toastify('success', t('messages.sign_in_success'));
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    setAuth(token: string, profile: Customer) {
        this.token = token;
        this.profile = profile;
    }

    *getProfile() {
        try {
            const res: ResponseData<Customer> = yield this.rootStore.apiStore.call(this.api, this.api.getProfile);
            if (res?.ok) {
                this.profile = res.data;
            }
        } catch (error) {
        }
    }

    setLiffID(payload) {
        this.liff_id = payload;
    }

    logout() {
        try {
            // this.rootStore.apiStore.call(this.api, this.api.logout);
            this.clearAuthentication();
        } catch (error) {
        }
    }

    setProfile(payload) {
        this.profile = payload;
    }

    *signinQrCode(payload) {
        try {
            const res: ResponseData<ResQrLogin> = yield this.rootStore.apiStore.call(this.api, this.api.signinByQrCode, payload);
            if (res.ok) {
                this.token = res.data.token;
                this.customer = res.data.user.resultUser[0];
                saveLocalStorage(keyStorage.TOKEN, res.data.token);
                saveLocalStorage(keyStorage.PROFILE_USER, JSON.stringify(res.data.user.resultUser[0]));
                saveLocalStorage(keyStorage.LINE_PROFILE_LINK, res.data.line_profile_link || '');
                toastify('success', t('messages.sign_in_success'));
            }
            return res.ok || false;
        } catch (error) {
            return false;
        }
    }

    clearAuthentication() {
        this.rootStore.apiStore.setLoading(true);
        removeStorage([keyStorage.TOKEN, keyStorage.PROFILE_USER, keyStorage.LINE_PROFILE_LINK, keyStorage.UUID, keyStorage.LIFF_ID]);
        setTimeout(() => {
            this.rootStore.apiStore.setLoading(false);
            window.location.href = ROUTES.login.href;
            try {
                liff.closeWindow();
            } catch (error) {
            }
        }, 2000);
    }
}
