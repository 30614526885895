import liff from '@line/liff';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStore } from '@/hooks/useStore';
import { toastify } from '@/utils/toastify';
import { Button } from '@/components/common/Button';
import Table from '@/components/table/Table';
import { ColumnDef } from '@tanstack/react-table';
import { BodyGetInvoices, File } from '@/types/files';
import { format } from 'date-fns';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import iconAddFriend from '../../assets/images/add-friend-white.png';
import iconCalendar from '../../assets/images/calendar.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { getStorage, saveLocalStorage } from '@/utils/browsers';
import { keyStorage } from '@/constant/string';
import { Download, LogOut } from 'lucide-react';
import { ja } from 'date-fns/locale';
import { t } from 'i18next';
import { Customer } from '@/types/auth';
import { flowResult, toJS } from 'mobx';
import { ROUTES } from '@/configs/constants';
import { AuthApi } from '@/apis';
import './index.scss';
import logo from '../../assets/images/logo_red.png';

export default observer(function HomePage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { isShowModal, checkIdLine } = location.state || {};
    const {
        modalStore: { showAlertModal, hideModal, showErrorModal },
        filesStore: { listInvoices, getListInvoices, keepSearchParams, setObservable },
        authStore: { logout, setProfile, profile },
        userStore: { getMyInfo },
        apiStore: { setLoading }
    } = useStore();
    const [params, setParams] = useState<BodyGetInvoices>(Object.keys(keepSearchParams).length > 0 ?
        { ...keepSearchParams } :
        {
            upload_month: (new Date().getMonth() + 1).toString(),
            upload_year: new Date().getFullYear().toString()
        });
    const [openDatePicker, setOpenDatePicke] = useState<boolean>(false);
    useEffect(() => {
        const handlePopState = () => {
            window.history.pushState(null, '', window.location.href);
            liff.closeWindow();
        };
        window.history.pushState(null, '', window.location.href);
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        if (checkIdLine) {
            setTimeout(() => {
                checkUserId();
            }, 2500);
        }
    }, [checkIdLine]);
    useEffect(() => {
        fetchListInvoices(params);
        // setTimeout(() => {
        //     displayPopupAddFriend();
        // }, 2000);
    }, []);

    useEffect(() => {
        if (isShowModal) {
            displayPopupAddFriend();
        } else {
            setTimeout(() => {
                displayPopupAddFriend();
            }, 1500);
        }
    }, [isShowModal]);

    const checkUserId = async () => {
        const profile: Customer | undefined = await flowResult(getMyInfo());
        if (!liff) {
            setTimeout(() => {
                checkUserId();
                return;
            }, 2500);
        }
        if (!profile) {
            return;
        }
        setProfile(profile);
        const id = liff.getIDToken();
        const lineProfile: any = await new AuthApi().getProfileLine(id);
        if (lineProfile && lineProfile.sub) {
            const line_id = lineProfile.sub;
            if (profile?.line_id !== line_id) {
                showErrorModal({
                    content: t('messages.account_changed')
                });
                setTimeout(() => {
                    logout();
                }, 2500);
            }
        }
    };

    const fetchListInvoices = async (payload = params) => {
        await getListInvoices(payload);
    };

    const displayPopupAddFriend = () => {
        liff.getFriendship().then((data) => {
            if (!data.friendFlag) {
                showAlertModal({
                    id: 'add-friend',
                    title: '友だち追加',
                    cancelButton: false,
                    saveButton: false,
                    showCloseIcon: false,
                    content: (
                        <div className='w-full text-start'>
                            <p className='mt-6 text-[14px] text-black'>・本サービスのご利用には、公式LINEアカウントの友達登録が必要です。</p>
                            <p className='text-[14px] text-black'>・本サービスの利用に際し、利用規約に同意する必要があります。</p>
                            <p className='text-[14px] text-black'>・「同意する」をクリックすることにより、利用規約の条件に同意したことになります。</p>
                            <div
                                className='text-blue-600 underline mt-2'
                                onClick={() => {
                                    liff.openWindow({
                                        url: 'https://docs.google.com/document/d/1xJWzUq_gg82K13mF7Yr3jmYTb1fPVHmx6PWfUCyGtd4/view',
                                        external: false
                                    });
                                }}
                            >
                                利用規約
                            </div>
                            <div className='flex flex-row text-center mt-8 w-full justify-center'>
                                <Button
                                    onClick={async () => {
                                        hideModal();
                                        handleAddFriend();
                                    }}
                                    className='min-w-32 bg-main'
                                >
                                    同意する
                                </Button>
                            </div>
                        </div>
                    )
                });
            }
        });
    };

    const handleAddFriend = async () => {
        const newProfile = await flowResult(getMyInfo());
        if (!newProfile) {
            showErrorModal({
                content: t('messages.account_invalid')
            });
            return;
        }
        const link = newProfile?.line_profile_link || undefined;
        const regex = /^(https?:\/\/)?([\w\-]+\.)+[a-z]{2,}(\/[^\s]*)?$/;
        if (!liff || !liff.isInClient()) {
            showErrorModal({
                content: t('messages.liff_error')
            });
            return;
        }
        if (!link || !regex.test(link)) {
            showErrorModal({
                content: t('messages.profile_invalid')
            });
            return;
        }
        if (link !== profile?.line_profile_link) {
            saveLocalStorage(keyStorage.LINE_PROFILE_LINK, link);
            setProfile({ ...profile, line_profile_link: link });
        }
        liff.openWindow({
            url: link,
            external: false
        });
    };

    const handleShare = async (id: string) => {
        try {
            const name = (await liff.getProfile()).displayName;
            const liff_id = getStorage(keyStorage.LIFF_ID);
            if (!liff_id) {
                return;
            }
            const permanentLink = await liff?.permanentLink.createUrlBy(
                `https://liff.line.me/${liff_id}?invitation=${id}`
            );
            const serviceLink = `https://liff.line.me/${liff_id}`;
            const res = await liff?.shareTargetPicker([
                {
                    type: 'text',
                    text: `You have shared rights to view invoices from ${name} via Invoice Manager System.\nClick the URL below to access:\n ${permanentLink}\n\nService Page:\n${serviceLink}`
                }
            ], {
                isMultiple: true
            });
            if (!res) return;
        } catch (error) {
            alert(t('messages.error'));
        }
    };

    const handleOpenFile = (path: string) => {
        if (!liff || !liff.isInClient()) {
            alert(t('messages.not_in_Line'));
            return;
        }
        if (!path) {
            alert(t('messages.error'));
            return;
        }
        const url = `https://drive.google.com/file/d/${path}/view`;
        try {
            liff.openWindow({
                url: url,
                external: false
            });
        } catch (error) {
            alert(t('messages.error'));
        }
    };

    const columns: ColumnDef<File>[] = [
        {
            header: ' ',
            size: 150,
            enableSorting: false,
            cell: ({ row: { original } }) => (
                <div className='text-center w-full'>
                    <p>
                        {format(original.created_at!, 'yyyy/MM/dd')}
                    </p>
                    <p>
                        {format(original.created_at!, 'HH:mm:ss')}
                    </p>
                </div>

            )
        },
        {
            header: ' ',
            size: 300,
            enableSorting: false,
            cell: ({ row: { original } }) => (
                <div
                    onClick={() => {
                        handleOpenFile(original.path);
                        // navigate(ROUTES.viewInvoice.href, { state: { id: original.id, nameFile: original.name, path: original.path } });
                    }}
                    className=' text-[15px] text-blue-600 underline text-left'
                >
                    {original.name}
                </div>
            )
        }
        // {
        //     header: ' ',
        //     size: 30,
        //     enableSorting: false,
        //     cell: ({ row: { original } }) => (
        //         liff.getOS() === 'ios' ?
        //             (
        //                 <Download
        //                     size={20}
        //                     onClick={() => {
        //                         liff.openWindow({
        //                             url: `https://drive.google.com/uc?export=download&id=${original.path}`,
        //                             external: true
        //                         });
        //                     }}
        //                 />
        //             ) :
        //             <a className='mt-4' href={`https://zept-invoice-api.vnit.top/rest/api/v1/end/invoice/download/${original.id}.pdf`} download={original.name}><Download size={20} /></a>
        //     )
        // }
    ];

    return (
        <div className='w-full h-full min-w-[350px]'>
            <div className='bg-white'>
                <div className='flex justify-center items-center content-center w-full p-4'>
                    <div
                        className='text-white flex justify-center text-4xl font-bold'
                    >
                        <img src={logo} className='w-[60%]' alt={logo} />
                    </div>
                    <button onClick={handleAddFriend} className='bg-main rounded-full w-[40px] h-[40px] flex justify-center items-center absolute right-[16px]'>
                        <img src={iconAddFriend} alt={iconAddFriend} />
                    </button>
                </div>
            </div>
            <div className='px-[16px] py-[24px]'>
                <div className='flex justify-start items-center'>
                    <DropdownMenu.Root open={openDatePicker} onOpenChange={setOpenDatePicke}>
                        <DropdownMenu.Trigger className='min-w-[250px] w-[40vw] h-[52px] px-4 py-1 flex justify-between items-center bg-white rounded-md text-[13px]'>
                            <p>{params.upload_year + '/' + ((parseInt(params.upload_month) < 10) ? '0' : '') + params.upload_month}</p>
                            <img src={iconCalendar} className='' alt={iconCalendar} />
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Portal>
                            <DropdownMenu.Content className=''>
                                <DatePicker
                                    selected={new Date(parseInt(params.upload_year), parseInt(params.upload_month) - 1)}
                                    selectsRange={true}
                                    dateFormat='MMM yyy'
                                    showMonthYearPicker
                                    inline
                                    onSelect={(date) => {
                                        if (!date) return;
                                        const param = {
                                            upload_month: `${(date.getMonth() + 1)}`,
                                            upload_year: `${date.getFullYear()}`
                                        };
                                        setParams(param);
                                        setObservable('keepSearchParams', toJS(param), { isMergeObject: false });
                                        setOpenDatePicke(false);
                                        fetchListInvoices(param);
                                    }}
                                    className='!w-[600px]'
                                    locale={ja}
                                />
                            </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                </div>
                <Table
                    columns={columns}
                    data={listInvoices}
                    getRowId={row => row.id.toString()}
                    containerClassName='mt-4'
                    headerClassName='h-0'
                    showSelectPageSize={false}
                    showPagination={false}
                    showPagingInfo={false}
                    noData={t('messages.no_invoice')}
                />
            </div>
        </div>
    );
});
